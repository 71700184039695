.box-time {
    color: white;
    margin-bottom: 0!important;
}

.box-unused {
    height: 120px;
    background-color: #272732;
}

.box-time-info-one {
    text-align: center;
    height: 120px;
    background-color: #191f27;
    margin-right: 0.5%;
    width: 30.4%!important;
}

.box-time-info-one-number {
    margin-top:6px!important;
    margin-left: 5px!important;
    color: #15FF63;
    font-weight: normal;
    font-size: xxx-large;
}

.box-time-info-one-title {
    font-size: medium;
}

.box-time-info {
    height: 120px;
    background-color: #191f27;
    text-align: center;
    margin-right: 0.1%;
    width: 17.16%!important;
}

.box-time-info-number {
    margin-top: 30px;
    font-size: calc(0.8em + 0.5vw);
}

.box-time-info-title {
    margin-top: 5px;
    font-size: calc(0.4em + 0.5vw);
}


.box-time-info-number-chico {
    margin-top: 20px;
    font-size: calc(0.5em + 0.5vw);
    height: 55px;
}

.box-time-info-title-chico {
    margin-top: 0px;
    font-size: calc(0.4em + 0.5vw);
}
