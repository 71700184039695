.iz-payload-avg {
  color: white;
  background-color: #191f27;
  width: 24%!important;
  height: 190px!important;
  margin-right: 0.7%!important;

}

.der-grafico-barras {
  color: white;
  background-color: #191f27;
  width: 73.7%!important;
  height: 190px!important;
  margin-right: 0.9%!important;
}

.graphbar {
    margin-bottom: 0!important;
}

.graphbar-title {
    color: white;
    padding: 0!important;
    padding-left: 20px!important;
    white-space: nowrap;
    text-align: left;
    font-size: medium;
    background-color: #191f27;
}

.graphbar-graph-row {
    margin-top: 2px;
}

.graphbar-graph {
    background-color: #0c1526;
}

.graphbar-graph-row-col {
    background-color: #191f27;
}

.grafico-barras{
    width: 75%!important;
    background-color: #191f27;
}

#aux_payload-avg-title{
  
}
