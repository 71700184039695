.graphbar {
    margin-bottom: 0!important;
}

.graphbar-title {
    color: white;
    padding: 0!important;
    padding-left: 20px!important;
    white-space: nowrap;
    text-align: left;
    font-size: medium;
    background-color: #0c1526;
}

.graphbar-graph-row {
    margin-top: 2px;
}

.graphbar-graph {
    background-color: #0c1526;
}

.graphbar-graph-row-col {
    background-color: #1d1c1c;
}

.box-unused {
    height: 120px;
    background-color: #272732;
}
