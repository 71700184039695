.titlePhoto{
  color: white;
  font-size: x-large;
}

.warningPhoto{
  color: yellow;
  font-size: large;
  margin-top: 9px;
}

.home-component-pics {
    background-color: #000000;
    margin-bottom: 0!important;
    height: 100%;
}

.home-component-row-pics {
    height: 100%!important;
    margin-left: 8px;
}

.home-mapRtm-pics-a  {
    margin-top: 1px;
    width: 37%!important;

}

.home-mapRtm-pics-b  {
    margin-top: 1px;
    width: 57%!important;

}

.home-graph-pics {
    margin-top: 1px;
    border-style: solid;
    width: 29.5%!important;
    height: 59%!important;
}

.home-monitor-barra-pics {
    overflow: scroll;
     overflow-y: hidden;
    margin-bottom: 0!important;
    height: max-content;
}

.home-monitor-pics {
    width: 67vh;
    height: 100%!important;
}

.home-monitor-barra-tr-pics {

}

.home-monitor-barra-table-pics {
    width: max-content;
}

.home-monitor-barra-tr-monitor-pics {}

.picstolva-pics {
    width: 100%;
    height: 100%;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
}


.waves-effect.waves-green {
   /* The alpha value allows the text and background color
   of the button to still show through. */
    background-color: rgba(6, 227, 35, 0.8);

  }

  .waves-effect.waves-greenpress {
    color:rgba(6, 227, 35, 0.1);
     background-color: rgba(6, 227, 35, 0.1);
  }
