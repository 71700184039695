.box-speed {
    color: white;
    height: 200px;
}

.box-speed-value {
    padding: 0!important;
    width: 50%!important;
}

.box-speed-title {
    font-size: x-large;
    text-align: left;
    background-color: #0f1c32;
}

.box-speed-info {
    width: 49%!important;
    text-align: center;
    margin-right: 1%;
    margin-top: 2px;
    background-color: #0f1c32;
}

.box-speed-info-value {
    white-space: nowrap;
    margin-top: 40px;
    font-size: 1.6em ;
}

.box-speed-info-title {
    font-size:  calc(0.5em + 0.5vw);
}

.box-speed-alert {
    width: 50%!important;
    background-color: #0f1c32;
    height: 100%!important;
    text-align: center;
    color: #fa0001;
}

.box-speed-alert-value {
    margin-top: 45px;
    font-size: xxx-large;
}

.box-speed-alert-title {
    font-size: x-large;
}

.box-speed-info-row { 
    height: 142px!important;
}
 