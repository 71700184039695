.aval {
    white-space: nowrap;
    color: white;
    margin-bottom: 0!important;
    font-size: 1.2em;
    background-color: #191f27;
}

.aval-overview {
    width: 40%!important;
    padding: 0!important;
}

.aval-overview-color {
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
}

.aval-bar {
    width: max-content!important;
    text-align: center;
}

.aval-title-avg {
    width: max-content!important;
    text-align: left;
}

.aval-title-truck {
    width: max-content!important;
    text-align: left;
}

.col-aval-graph {
    padding-top:20px!important;
    padding-bottom:20px!important;
    overflow-x: auto;
    overflow-y: hidden;
    margin:0!important;
    padding:0!important;
    padding-top: 20px!important;
    padding-bottom: 20px!important;
}

.aval-graph-table {
    width: max-content;
 }

.aval-graph-tr {}

.aval-graph-td {
    width: 23vh;
    height: 100%!important;
    margin:0!important;
    padding:0!important;
}
