.unit-component {
    background-color: #333544;
    margin-bottom: 0!important;
}

.unit-map {
    margin-top: -10px;
}

.unit-datatable {
    margin-top: 5px;
    width: 100%!important;
    height: 380px;
    
}

.unit-monitor-barra {
    overflow: scroll;
    overflow-y: hidden!important;
    margin-bottom: 0!important;
}

.unit-monitor {}


.unit-all-date-range {
    margin-top: 5px;
    margin-left: 11px!important;
} 