.box-time {
    color: white;
    margin-bottom: 0!important;
}

.box-time-info-one {
    text-align: center;
    height: 120px;
    background-color: #191f27;
    margin-right: 0.5%;
    width: 30.4%!important;
}

.box-time-info-one-number {
    margin-top: 6px!important;
    margin-bottom: -12px!important;
    margin-left: 5px!important;
    color: #15FF63;
    font-weight: bold;
    font-size: xxx-large;
}

.box-time-info-one-title {
    font-size: x-large;
}

.box-time-info {
    height: 120px;
    text-align: center;
    margin-right: 0.3%;
    width: 16.97%!important;
}

.box-time-info-number {
    margin-top: 15px;
    text-align: center;
    font-size: x-large;
}

.box-time-info-number-b {
    margin-top: 15px;
    text-align: center;
    font-size: xxx-large;
}

.box-time-info-number-h {
    margin-top: 30px;
    text-align: center;
    font-size: xx-large;
}

.box-time-info-title {
    margin-top:11px;
    text-align: center;
    margin-bottom: 10px!important;
    font-size: large;
}

.box-time-info-title-h {
    margin-top:20px;
    text-align: center;
    margin-bottom: 10px!important;
    font-size: large;
}

.cycle-analytics {
  color: white;
  margin-top: 5px!important;
  margin-bottom: 5px!important;
}

.trucks-in-operation {
  margin-left: 0.5%!important;
  width: 19.5%!important;
  /*height: 207px;*/
  height: 135px;
  background-color: #554A7B;
}

.hours-of-operation {
  margin-left: 0.5%!important;
  width: 19.5%!important;
  height: 135px;
  background-color: #2C1777;
}

.cycles-today {
  margin-left: 0.5%!important;
  width: 19.5%!important;
  /*height: 207px;*/
  height: 135px;
  background-color: #172B4C;
}

.speeding-alarms {
  margin-left: 0.5%!important;
  width: 19.5%!important;
  /*height: 207px;*/
  height: 135px;
  background-color: #424C58;
}

.km-today {
  margin-left: 0.5%!important;
  width: 19.5%!important;
  /*height: 207px;*/
  height: 135px;
  background-color: #332D4A;
}
