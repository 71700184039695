.bar {
    color: white;
    height: 35px;
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 0!important;
    background-color: #1d1c1c;
}

.bar-title {
  margin-top: 5px;
    width: max-content!important;
    margin-left: 1em!important;
    margin-right: 3em!important;
}

.bar-overview {
  margin-top: 5px;
  margin-bottom: 5!important;
    margin-right: 3%!important;
    width: max-content!important;
    background-color: #1b1b1b;
}

.bar-sub-title {
    padding-right: 3%!important;
    text-align: center;
    width: max-content!important;
    margin-right: 0.1em!important;
}

.bar-button {
    padding: 0!important;
    margin: 10!important;
    width: max-content!important;
    margin-left: 10px!important;
  }

.bar-button-a {
    width: 14px;
    height: 25px;
    background-color: rgba(6,227,35,255)!important;
}

.bar-button-a-i {
    margin-left: -10px!important;
    font-size: 1rem!important;
}


.btn_selectP {
  color: white;
  font-size: 0.9em;
  margin-top: 1!important;
  background-color: #1d1c1c;
  border: 1px solid white;
  border-radius: 5px;
  border-color: #15FF63;
  padding: 2px;
  cursor: pointer;
}

.btn_noselectP {
  color: white;
  font-size: 0.9em;
  margin-top: 5!important;
  background-color: #1d1c1c;
  padding: 4px;
  cursor: pointer;
}

.waves-effect.waves-green {
   /* The alpha value allows the text and background color
   of the button to still show through. */
    background-color: rgba(6, 227, 35, 0.8);
  }
