.payload3 {

}

.payload3-graph {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    padding-right: 0px!important;
    padding-left: 0px!important;
    width: 98.7%!important;
    background-color: #19283f;
}


.payload3-avg {
    color: white;
    background-color: #191f27;
    width: 24%!important;
    height: 189px!important;
    margin-right: 1%!important;
}

.payload3-avg-row {
    padding: 0!important;
    margin: 0!important;
}

.payload3-avg-title {
    margin-top: 30px;
    text-align: center;
    font-size: 1.5em;
}

.payload3-avg-value {
    white-space: nowrap;
    text-align: center;
    margin-left:-10px!important;
    font-size: 2.2em;
}

.apexcharts-tooltip {
  background: #FFFFFF;
  color: black;
}
