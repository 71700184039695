.home-component-pics {
    background-color: #000000;
    margin-bottom: 0!important;
    height: 92%;
}

.home-component-row-pics {
    height: 100%!important;
}

.home-mapRtm-pics {
    margin-top: 5px;
    width: 45%!important;
}

.home-graph-pics {
    margin-top: 5px;
    border-style: solid;
    width: 29.5%!important;
    height: 59%!important;
}

.home-monitor-barra-pics {
    overflow: scroll;
     overflow-y: hidden;
    margin-bottom: 0!important;
    height: max-content;
}

.home-monitor-pics {
    width: 67vh;
    height: 100%!important;
}

.home-monitor-barra-tr-pics {

}

.home-monitor-barra-table-pics {
    width: max-content;
}

.home-monitor-barra-tr-monitor-pics {}

.picstolva-pics {
    width: 100%;
    height: 100%;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
}
