.rw_row {
  margin-left: 10px;
  margin-bottom: 1px;
}

.rw_cell {
    background-color: #1d1c1c;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
    padding: 0px;
    height: 80px;

}

.rw_cell_box {
  text-align: center;
  margin-top: 10px;
  border: 1px solid white;
  border-radius: 5px;
  border-color: #FFFFFF;
  padding: 5px;
}

.rw_position {
    width: 50px;
    margin-left: 30px;
}

.rw_sensor {
    width: 100px;
    margin-left: 10px;
}

.rw_batery {
  text-align: center;
  align-items: center;
  width: 50px;
  margin-left: 52px;
}

.rw_connection {
  text-align: center;
  width: 50px;
  margin-left: 85px;
}
