#Tolva      {
	width: 339px;
	height: 535px;
	position: relative;
	top: 0px;
	left: 0px;
	background-color: transparent;
}

.imgtolva{
	width: 150%;
	height: 150%;
}

#ledp1      {
  width: 30px;
	height: 30px;
	position: relative;
	top: -284px;
	left: 240px;
	background-color: transparent;
}

#ledp2      {
  width: 30px;
	height: 30px;
	position: relative;
	top: -81px;
	left: 95px;
	background-color: transparent;
}

#ledp3      {
  width: 30px;
	height: 30px;
	position: relative;
	top: -111px;
	left: 190px;
	background-color: transparent;
}

#ledp4      {
  width: 30px;
	height: 30px;
	position: relative;
	top: -141px;
	left: 276px;
	background-color: transparent;
}

#ledp5      {
  width: 30px;
	height: 30px;
	position: relative;
	top: -171px;
	left: 371px;
	background-color: transparent;
}

#ledp6      {
	width: 30px;
	height: 30px;
	position: relative;
	top: -36px;
	left: 95px;
	background-color: transparent;
}

#ledp7      {
	width: 30px;
	height: 30px;
	position: relative;
	top: -66px;
	left: 190px;
	background-color: transparent;
}

#ledp8      {
  width: 30px;
	height: 30px;
	position: relative;
	top: -96px;
	left: 276px;
	background-color: transparent;
}

#ledp9      {
	width: 30px;
	height: 30px;
	position: relative;
	top: -126px;
	left: 371px;
	background-color: transparent;
}

.p1_rojo{
	width: 150%;
	height: 150%;
}

.p2_rojo{
	width: 150%;
	height: 150%;
}

.p3_rojo{
	width: 150%;
	height: 150%;
}

.p4_rojo{
	width: 150%;
	height: 150%;
}

.p5_rojo{
	width: 150%;
	height: 150%;
}

.p6_rojo{
	width: 150%;
	height: 150%;
}

.p7_rojo{
	width: 150%;
	height: 150%;
}

.p8_rojo{
	width: 150%;
	height: 150%;
}

.p9_rojo{
	width: 150%;
	height: 150%;
}

.p1_verde{
	width: 150%;
	height: 150%;
}

.p2_verde{
	width: 150%;
	height: 150%;
}

.p3_verde{
	width: 150%;
	height: 150%;
}

.p4_verde{
	width: 150%;
	height: 150%;
}

.p5_verde{
	width: 150%;
	height: 150%;
}

.p6_verde{
	width: 150%;
	height: 150%;
}

.p7_verde{
	width: 150%;
	height: 150%;
}

.p8_verde{
	width: 150%;
	height: 150%;
}

.p9_verde{
	width: 150%;
	height: 150%;
}



.p1_gris{
	width: 150%;
	height: 150%;
}

.p2_gris{
	width: 150%;
	height: 150%;
}

.p3_gris{
	width: 150%;
	height: 150%;
}

.p4_gris{
	width: 150%;
	height: 150%;
}

.p5_gris{
	width: 150%;
	height: 150%;
}

.p6_gris{
	width: 150%;
	height: 150%;
}

.p7_gris{
	width: 150%;
	height: 150%;
}

.p8_gris{
	width: 150%;
	height: 150%;
}

.p9_gris{
	width: 150%;
	height: 150%;
}
