.table-component-row {
    margin: 0!important;
    padding: 0!important;
}

.table-all-date-range {
    margin-top: 3px!important;
    margin-bottom: 10px!important;
}

.table-datatable {
    padding: 0!important;
    margin: 0!important;
}

.table-map1 {
    padding: 0!important;
    margin-top: 5px!important;
    padding-right: 5px!important;
}

.table-map2 {
    padding: 0!important;
    margin-top: 5px!important;
}

.table-map1-title {
    padding: 0!important;
    margin: 0!important;
    font-size: large;
    color: white;
    width: 98.6%!important;
    padding-left: 20px!important;
}

.table-map2-title {
    padding: 0!important;
    margin: 0!important;
    width: 100%!important;
    padding-left: 10px!important;
    margin-left: 10px!important;
    font-size: large;
    color: white;
}
