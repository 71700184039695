.box-time-g {
    color: white;
    margin-bottom: 0!important;
}

.box-time-info-one-g {
    text-align: center;
    height: 120px;
    background-color: #191f27;
    margin-right: 0.5%;
    width: 12.0%!important;
}

.box-time-info-one-number-g {
    margin-top: 6px!important;
    margin-bottom: -12px!important;
    margin-left: 5px!important;
    color: #15FF63;
    font-weight: bold;
    font-size: xxx-large;
}

.box-time-info-one-title-g {
    font-size: x-large;
}

.box-time-info-g {
    height: 120px;
    text-align: center;
    margin-right: 0.3%;
    width: 12.0%!important;
}

.box-time-info-number-g {
    margin-top: 15px;
    text-align: center;
    font-size: x-large;
}

.box-time-info-number-b-g {
    margin-top: 20px;
    text-align: center;
    font-size: xx-large;
}

.box-time-info-number-h-g {
    margin-top: 25px;
    text-align: center;
    font-size: x-large;
}

.box-time-info-title-g {
    margin-top:0px;
    text-align: center;
    margin-bottom: 2px!important;
    font-size: medium;
}

.box-time-info-title-b-g {
    margin-top:10px;
    text-align: center;
    margin-bottom: 0px!important;
    font-size: 85%;
}

.box-time-info-title-h-g {
    margin-top:20px;
    text-align: center;
    margin-bottom: 10px!important;
    font-size: large;
}

.cycle-analytics-g {
  color: white;
  margin-top: 5px!important;
  margin-bottom: 5px!important;
}

.trucks-in-operation-g {
  margin-left: 0.5%!important;
  width: 12.0%!important;
  /*height: 207px;*/
  height: 135px;
  background-color: #554A7B;
}

.hours-of-operation-g {
  margin-left: 0.5%!important;
  width: 12.0%!important;
  height: 135px;
  background-color: #2C1777;
}

.cycles-today-g {
  margin-left: 0.5%!important;
  width: 12%!important;
  /*height: 207px;*/
  height: 135px;
  background-color: #172B4C;
}

.speeding-alarms-g {
  margin-left: 0.5%!important;
  width: 12%!important;
  /*height: 207px;*/
  height: 135px;
  background-color: #424C58;
}

.km-today-g {
  margin-left: 0.5%!important;
  width: 12%!important;
  /*height: 207px;*/
  height: 135px;
  background-color: #332D4A;
}
