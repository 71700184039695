.selector-date-select {
    background-color: #191f27;
    margin-bottom: 0!important;
    margin-right: 2px!important;
}

.selector-date-date-range {
    display: inline;
    margin-left: 5%!important;
}

.selector-date-radio-text {}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after {
      background-color: #15FF63;
      border-color: #15FF63;
}
