.relleno {
  height: 12%;
}

.login img {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top:0;
    left: 0;
    z-index: -1;
}

.login-box {
    background-color:rgba(117, 117, 115, 0.1);
}

.waves-effect.waves-green {
   /* The alpha value allows the text and background color
   of the button to still show through. */
    background-color: rgba(6, 227, 35, 0.6);
  }

/* Este cambia el color del label del campo cuando no está seleccionado */
.input-field label {
  color: rgba(6,227,35,255)!important; /* Donde dice #000 pones el color que quieres */
}

.input-field label.active {
  color: rgba(6,227,35,255)!important; /* Donde dice #000 pones el color que quieres */
}

/* Este es para cambiar el color del label cuando seleccionas el campo */
.input-field input[type=text]:focus + label {
  color: rgba(6,227,35,0.1) !important; /* Por ejemplo, acá saco el verde y pongo por azul */
}

.input-field input[type=password]:focus + label {
  color: rgba(6,227,35,0.1) !important; /* Por ejemplo, acá saco el verde y pongo por azul */
}

/* Este es para cambiar el color de la línea del campo cuando lo seleccionas */
.input-field input[type=text]:focus {
  border-bottom: 1px solid rgba(6,227,35,255) !important; /* El #000 es el color de la línea */
  box-shadow: 0 1px 0 0 rgba(6,227,35,0.1) !important; /* Y acá, de la pequeña sombra que genera */
}

.input-field input[type=text] {
  border-bottom: 1px solid rgba(6,227,35,255) !important; /* El #000 es el color de la línea */
  box-shadow: 0 1px 0 0 rgba(6,227,35,0.1) !important; /* Y acá, de la pequeña sombra que genera */
}

.input-field input[type=password] {
  border-bottom: 1px solid rgba(6,227,35,255)!important; /* El #000 es el color de la línea */
  box-shadow: 0 1px 0 0 rgba(6,227,35,0.1)!important; /* Y acá, de la pequeña sombra que genera */
}

/* Si usas la propiedad validate, el color de la línea al validar, será este */
.input-field input[type=text].valid {
  border-bottom: 1px solid rgba(6,227,35,255)!important;
  box-shadow: 0 1px 0 0 rgba(6,227,35,255)!important;
}

/* Y este será el color en el caso que se tome como inválido */
.input-field input[type=text].invalid {
  border-bottom: 1px solid rgba(6,227,35,255)!important;
  box-shadow: 0 1px 0 0 rgba(6,227,35,255)!important;
}

/* Pôr último, este cambia el color al ícono cuando entras a un campo */
.input-field .prefix.active {
  color: rgba(6,227,35,255)!important; /* También quieres cambiar esto a otro color, o no? */
}

.input-field .prefix {
  color: rgba(6,227,35,0.5)!important; /* También quieres cambiar esto a otro color, o no? */
}
