#row_top   {
  z-index: 2;
  width: '50%';
	height: '50%';
	position: relative;
	top:  20px;
	left: 140px;
	background-color: transparent;
}

#row_bottom {
  z-index: 1;
	position: relative;
	top:  -157px;
	left: 1px;
	background-color: transparent;
  width: 95%!important;
  margin-left: 3%!important;
}

.graphmultiline {
    height: 200px;
    margin-bottom: 0!important;
}

.graphmultiline-avg {
    color: white;
    background-color: #172a4b;
    width: 15%!important;
    height: 100%;
    margin-right: 1%!important;
}

.graphmultiline-avg-title {
    margin-top: 70px;
    text-align: center;
    font-size:  large;
}

.graphmultiline-avg-value {
    text-align: center;
    font-size: xxx-large;
}

.graphmultiline-graph {
    padding:0!important;
    padding-top: 2px!important;
    padding-bottom: 10px!important;
    width: 84%!important;
    background-color: #191f27;
    height: 100%;
}

.graphmultiline-avg-subtitle {
    text-align: center;
    color: white;
    font-size: medium;
}

.graphmultiline-graph-col {
  height: 197px;
  width: 95%!important;
  margin-left: 3%!important;
}
