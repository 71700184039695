.bar {
    color: white;
    height: 35px;
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 0!important;
    background-color: #1d1c1c;
}

.bar-title {
  margin-top: 5px;
    width: max-content!important;
    margin-left: 1em!important;
    margin-right: 3em!important;
}

.bar-overview {
  margin-top: 5px;
  margin-bottom: 5!important;
    margin-right: 3%!important;
    width: max-content!important;
    background-color: #1b1b1b;
}

.bar-sub-title {
    padding-right: 3%!important;
    text-align: center;
    width: max-content!important;
    margin-right: 0.1em!important;
}

.bar-button {
    padding: 0!important;
    margin: 10!important;
    width: max-content!important;
    margin-left: 10px!important;
}

.bar-button-a {
    background-color: grey;
    width: 14px;
    height: 25px;
}

.bar-button-a-i {
    margin-left: -10px!important;
    font-size: 1rem!important;
}


.btn_select {
  color: white;
  font-size: 0.9em;
  margin-top: 1!important;
  background-color: #1d1c1c;
  border: 1px solid white;
  border-radius: 5px;
  border-color: #15FF63;
  padding: 2px;
}

.btn_noselect {
  cursor: pointer;
  color: white;
  font-size: 0.9em;
  margin-top: 5!important;
  background-color: #1d1c1c;
  padding: 4px;
}
