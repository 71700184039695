.home-component {
    background-color: #000000;
    margin-bottom: 0!important;
    height: 92%;
}

.home-component-row {
    height: 100%!important;
}

.home-map {
    margin-top: 0px;
    width: 100%!important;
}

.home-graph {
    margin-top: 5px;
    border-style: solid;
    width: 29.5%!important;
    height: 56%!important;
}

.home-monitor-barra {
    overflow: scroll;
     overflow-y: hidden;
    margin-bottom: 0!important;
    height: max-content;
}

.home-monitor {
    width: 67vh;
    height: 100%!important;
}

.home-monitor-barra-tr {

}

.home-monitor-barra-table {
    width: max-content;
}

.home-monitor-barra-tr-monitor {}
