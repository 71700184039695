.filaGinGin1{
  margin-top: 0px!important;
  margin-bottom: 0px!important;
}

.filaGinGin2{
  margin-top: 0px!important;
  margin-bottom: 0px!important;
}
.colGinGinIzq2{
  height: 163px;
  width: 70%!important;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
}
.colGinGinDer2{
  height: 150px;
  width: 30%!important;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
}



.filaGinGin3{
  background-color: #FDF6EC;
  height: 50px;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
}
.filaGinGin4{
  background-color: #B7CADB;
  height: 50px;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
}





.gbox-row{
  background-color: #292f17;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  height: 150px;
}


.board-row {}

.board-button {}

.board-bar-title {}

.board-margin-bottom {
    margin-bottom: 8px!important;
}

.board-selector-date {}

.board-box-time {}

.board-payload {
    padding: 0!important;
}

.board-cycle {}

.board-box-speed {
    margin-top: 2px!important;
}

.board-donut {
    margin: 0!important;
    padding: 0!important;
    height: 25.4em!important;
}

@media only screen and (min-width: 960px) {
    .board-donut-r {
        height: 27.2em!important;
    }
}

.board-monitor-title {
    color: white;
    font-size: large;
    background-color: #191f27;
    height: 10%!important;
    margin-top: 3px!important;
}

.board-monitor {
    padding: 0!important;
}

.box-iz {
    margin-right: 1%;
    margin-left: 0.5%;
    width: 49%!important;
    height:165px;
}

.box-der {
    width: 49%!important;
    height:165px;
}

.box-der-row {
}

.gbox-componente-down {
  margin-top: 0px;
}

.table-datatable {
    padding: 10px;
    margin-top: 20px;
}

.TimeonEach{
  background: #191f27;
  color: #FFFFFF;
  font-size: large;
  margin-bottom: 2px;
}
