.gboxContent{
  margin-top: 3px;
}

.gbox-time-info {
  color: white;
    height: 133px;
    background-color: #191f27;
    text-align: center;
    margin-right: 0.1%;
    width: 14.03%!important;
}

.gbox-time-info-number-chico {
    margin-top: 20px;
    font-size: calc(0.5em + 0.5vw);
    height: 55px;
}


.gbox-time {
    color: white;
    margin-bottom: 0!important;
}

.gbox-unused {
    height: 120px;
    background-color: #191f27;
}

.gbox-time-info-one {
    text-align: center;
    height: 120px;
    background-color: #191f27;
    margin-right: 0.5%;
    width: 30.4%!important;
}

.gbox-time-info-one-number {
    margin-top:0px!important;
    margin-left:5px!important;
    color: #15FF63;
    font-weight: normal;
    font-size: xxx-large;
}

.gbox-time-info-one-title {
    font-size: medium;
}



.gbox-time-info-number {
    margin-top: 0px;
    font-size: calc(0.8em + 0.5vw);
}

.gbox-time-info-title {
    margin-top: 0px;
    font-size: calc(0.4em + 0.5vw);
}

.gbox-time-info-title-chico {
    margin-top: 0px;
    font-size: calc(0.4em + 0.5vw);
}
