.cycle {
    height: 200px;
}

.cycle-title {
    font-size: x-large;
    background-color: #172a4b;
    color: white;
}

.cycle-avg {
    color: white;
    background-color: #172a4b;
    width: 24.7%!important;
    height: 100%;
    margin-left: 0.3%!important;
}

.cycle-avg-row {}

.cycle-avg-title {
    text-align: center;
    font-size: calc(1em + 0.9vw);
}

.cycle-avg-value {
    margin-left: -5px!important;
    margin-top: calc(0.01em);
    white-space: nowrap;
    text-align: center;
    font-size: xxx-large;
}

.cycle-graph {
    padding-top: 20px!important;
    padding-bottom: 10px!important;
    background-color: #172a4b;
    width: 75%!important;
    height: 100%;
}