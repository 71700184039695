
.dash{
    width: 100%!important;
}
.dash-nav-nav{
    height: 50px!important;
    line-height: 35px;
}
.dash .head-nav, .dash-nav nav{
    padding-left: 12px;
    padding-right: 15px;
}

.dash .head-nav{
    background-color:#222a35;
}

.dash-nav nav{
    background-color:#323f4f;
}
.right{
    font-size: 1vw;
}
.brand-logo{
    font-size: 20px!important;  
}
@media only screen and (min-width: 601px){
    .head-nav, .head-nav .head-nav.nav-wrapper i, .head-nav a.sidenav-trigger, .head-nav a.sidenav-trigger i {
        height: 20px;
        line-height: 20px;
    }
}