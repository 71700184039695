.boxPayload-time {
    color: white;
    margin-bottom: 0!important;
    font-size: xxx-large;
}

.boxPayload-time-info {
    height: 220px;
    background-color: #191f27;
    text-align: center;
    margin-right: 0.5%;
    width: 32.8%!important;
}

.boxPayload-time-info-number {
    margin-top: 30px;
    font-size: calc(0.8em + 0.5vw);
}

.boxPayload-time-info-one-number {
    margin-top: 6px!important;
    margin-left: 5px!important;
    color: #15FF63;
    font-weight: normal;
    font-size: xxx-large;
}

.boxPayload-time-info-title {
    margin-top: 5px;
    font-size: x-large;
}
