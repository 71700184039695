a {
  color: #00B7FF;
}

h2 {
  color: #EBF5FB;
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

*{box-sizing:border-box;}

.box-response{
  width:600px;
	padding:16px;
	border-radius:10px;
	margin:auto;
	background-color:#ccc;
  text-align: center;
}

form{
	width:600px;
	padding:16px;
	border-radius:10px;
	margin:auto;
	background-color:#ccc;
}

form label{
  font: 15px "Lucida Grande", Helvetica, Arial, sans-serif;
	width:300px;
	font-weight:bold;
	display:inline-block;
  color: green;
}

form input[type="text"],
form input[type="email"]{
	width:200;
	padding:3px 10px;
	border:1px solid #f6f6f6;
	border-radius:3px;
	background-color:#f6f6f6;
	margin:8px 0;
	display:inline-block;
}

form input[type="submit"]{
	width:35%;
	padding:8px 16px;
	margin-top:32px;
	border:1px solid rgba(6,227,35,0.6);
	border-radius:5px;
  box-shadow: 2px 2px 2px 2px rgba(192,192,192,1);
	display:block;
	color:#fff;
	background-color:rgba(6,227,35,255)!important;
}

form input[type="submit"]:hover{
	cursor:pointer;
  box-shadow: 2px 2px 2px 1px rgba(64,64,64,0.6);
}

form input[type="submit"]:hover{
	cursor:pointer;
  box-shadow: 2px 2px 2px 1px rgba(64,64,64,0.6);
}

form input[type="submit"]:active{
	cursor:pointer;
  background-color:rgba(6,227,35,0.5) !important;
  color:rgba(0,102,0,0.5);
  box-shadow: 0px 0px 0px 0px rgba(64,64,64,0.6);
}

/* Este es para cambiar el color de la línea del campo cuando lo seleccionas */
form input[type=text]:focus {
  border-bottom: 1px solid rgba(6,227,35,255) !important; /* El #000 es el color de la línea */
  box-shadow: 0 1px 0 0 rgba(6,227,35,0.1) !important; /* Y acá, de la pequeña sombra que genera */
}

textarea{
	width:100%;
	height:100px;
	border:1px solid #f6f6f6;
	border-radius:3px;
	background-color:#f6f6f6;
	margin:8px 0;
	/*resize: vertical | horizontal | none | both*/
	resize:none;
	display:block;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
}

li {
    float: left;
}

li a, .dropbtn {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
    background-color: #111;
}


li.dropdown {
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
    display: block;
}

/*.active {
    background-color: #4CAF50;
}*/

.button
    {
        display: inline-block;
        white-space: nowrap;
        background-color: #ddd;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#ccc));
        background-image: -webkit-linear-gradient(top, #eee, #ccc);
        background-image: -moz-linear-gradient(top, #eee, #ccc);
        background-image: -ms-linear-gradient(top, #eee, #ccc);
        background-image: -o-linear-gradient(top, #eee, #ccc);
        background-image: linear-gradient(top, #eee, #ccc);
        border: 1px solid #777;
        padding: 0 1.5em;
        margin: 0.5em;
        font: bold 1em/2em Arial, Helvetica;
        text-decoration: none;
        color: #333;
        text-shadow: 0 1px 0 rgba(255,255,255,.8);
        -moz-border-radius: .2em;
        -webkit-border-radius: .2em;
        border-radius: .2em;
        -moz-box-shadow: 0 0 1px 1px rgba(255,255,255,.8) inset, 0 1px 0 rgba(0,0,0,.3);
        -webkit-box-shadow: 0 0 1px 1px rgba(255,255,255,.8) inset, 0 1px 0 rgba(0,0,0,.3);
        box-shadow: 0 0 1px 1px rgba(255,255,255,.8) inset, 0 1px 0 rgba(0,0,0,.3);
    }

    .button:hover
    {
        background-color: #eee;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#fafafa), to(#ddd));
        background-image: -webkit-linear-gradient(top, #fafafa, #ddd);
        background-image: -moz-linear-gradient(top, #fafafa, #ddd);
        background-image: -ms-linear-gradient(top, #fafafa, #ddd);
        background-image: -o-linear-gradient(top, #fafafa, #ddd);
        background-image: linear-gradient(top, #fafafa, #ddd);
    }

    .button:active
    {
        -moz-box-shadow: 0 0 4px 2px rgba(0,0,0,.3) inset;
        -webkit-box-shadow: 0 0 4px 2px rgba(0,0,0,.3) inset;
        box-shadow: 0 0 4px 2px rgba(0,0,0,.3) inset;
        position: relative;
        top: 1px;
    }

    .button:focus
    {
        outline: 0;
        background: #fafafa;
    }

    .button:before
    {
        background: #ccc;
        background: rgba(0,0,0,.1);
        float: left;
        width: 1em;
        text-align: center;
        font-size: 1.5em;
        margin: 0 1em 0 -1em;
        padding: 0 .2em;
        -moz-box-shadow: 1px 0 0 rgba(0,0,0,.5), 2px 0 0 rgba(255,255,255,.5);
        -webkit-box-shadow: 1px 0 0 rgba(0,0,0,.5), 2px 0 0 rgba(255,255,255,.5);
        box-shadow: 1px 0 0 rgba(0,0,0,.5), 2px 0 0 rgba(255,255,255,.5);
        -moz-border-radius: .15em 0 0 .15em;
        -webkit-border-radius: .15em 0 0 .15em;
        border-radius: .15em 0 0 .15em;
        pointer-events: none;
    }

	/* Buttons and inputs */

	button.button, input.button
	{
		cursor: pointer;
		overflow: visible; /* removes extra side spacing in IE */
	}

	/* removes extra inner spacing in Firefox */
	button::-moz-focus-inner
	{
	  border: 0;
	  padding: 0;
	}

	/* If line-height can't be modified, then fix Firefox spacing with padding */
	 input::-moz-focus-inner
	{
	  padding: .4em;
	}

	/* The disabled styles */
	.button[disabled], .button[disabled]:hover, .button.disabled, .button.disabled:hover
	{
		background: #eee;
		color: #aaa;
		border-color: #aaa;
		cursor: default;
		text-shadow: none;
		position: static;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

    /* Hexadecimal entities for the icons */

    .add:before
    {
        content: "\271A";
    }

    .edit:before
    {
        content: "\270E";
    }

    .delete:before
    {
        content: "\2718";
    }

    .save:before
    {
        content: "\2714";
    }

    .email:before
    {
        content: "\2709";
    }

    .like:before
    {
        content: "\2764";
    }

    .next:before
    {
        content: "\279C";
    }

    .star:before
    {
        content: "\2605";
    }

    .spark:before
    {
        content: "\2737";
    }

    .play:before
    {
        content: "\25B6";
    }


	/* Social media buttons */
	.tw, .fb,
	.tw:hover, .fb:hover
	{
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,.5)), to(rgba(255,255,255,0)));
        background-image: -webkit-linear-gradient(top, rgba(255,255,255,.5), rgba(255,255,255,0));
        background-image: -moz-linear-gradient(top, rgba(255,255,255,.5), rgba(255,255,255,0));
        background-image: -ms-linear-gradient(top, rgba(255,255,255,.5), rgba(255,255,255,0));
        background-image: -o-linear-gradient(top, rgba(255,255,255,.5), rgba(255,255,255,0));
        background-image: linear-gradient(top, rgba(255,255,255,.5), rgba(255,255,255,0));
	}

	.tw, .tw:focus
	{
	  background-color: #88E1E6;
	}

	.fb, .fb:focus
	{
	  background-color: #3C5A98;
	  color: #fff;
	  text-shadow: 0 1px 0 rgba(0,0,0,.4);
	}

	.tw:hover
	{
	  background-color: #b1f0f3;
	}

	.fb:hover
	{
	  background-color: #879bc3;
	}

    .tw:before
    {
        content: "t";
        background: #91cfd3;
        background: rgba(0,0,0,.1);
		color: #fff;
		font-family: verdana;
		text-shadow: 0 1px 0 rgba(0,0,0,.4);
    }

    .fb:before
    {
        content: "f";
        background: #4467ac;
        background: rgba(0,0,0,.1);
		color: #fff;
		text-shadow: 0 1px 0 rgba(0,0,0,.4);
    }

    .datagrid table {
    	border-collapse: collapse;
    	text-align: left;
    }

    .datagrid {
      width: 80%;
    	font: normal 12px/150% Arial, Helvetica, sans-serif;
    	background: #fff;
    	overflow: hidden;
    	border: 1px solid #8C8C8C;
    	-webkit-border-radius: 3px;
    	-moz-border-radius: 3px;
    	border-radius: 3px;

    }

    .datagrid table td, .datagrid table th { padding: 3px 10px; }
    .datagrid table thead th {
    	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #8C8C8C), color-stop(1, #7D7D7D) );
    	background:-moz-linear-gradient( center top, #8C8C8C 5%, #7D7D7D 100% );
    	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C8C8C', endColorstr='#7D7D7D');
    	background-color:#8C8C8C;
    	color:#FFFFFF;
    	font-size: 15px;
    	font-weight: bold;
    	border-left: 1px solid #A3A3A3;
    }

    .datagrid table thead th:first-child { border: none; }
    .datagrid table tbody td {
    	color: #7D7D7D;
    	border-left: 1px solid #DBDBDB;
    	font-size: 12px;
    	font-weight: normal;
    }

    .datagrid table tbody .alt td {
    	background: #EBEBEB;
    	color: #7D7D7D;
    }

    .datagrid table tbody td:first-child { border-left: none; }
    .datagrid table tbody tr:last-child td { border-bottom: none; }
    .datagrid table tfoot td div {
    	border-top: 1px solid #8C8C8C;
    	background: #EBEBEB;
    }

    .datagrid table tfoot td {
    	padding: 0;
    	font-size: 12px
    }

    .datagrid table tfoot td div{ padding: 2px; }
    .datagrid table tfoot td ul {
    	margin: 0;
    	padding:0;
    	list-style: none;
    	text-align: right;
    }

    .datagrid table tfoot  li { display: inline; }
    .datagrid table tfoot li a {
    	text-decoration: none;
    	display: inline-block;
    	padding: 2px 8px;
    	margin: 1px;
    	color: #F5F5F5;
    	border: 1px solid #8C8C8C;
    	-webkit-border-radius: 3px;
    	-moz-border-radius: 3px;
    	border-radius: 3px;
    	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #8C8C8C), color-stop(1, #7D7D7D) );
    	background:-moz-linear-gradient( center top, #8C8C8C 5%, #7D7D7D 100% );filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C8C8C', endColorstr='#7D7D7D');
    	background-color:#8C8C8C;
    }

    .datagrid table tfoot ul.active, .datagrid table tfoot ul a:hover {
    	text-decoration: none;
    	border-color: #7D7D7D;
    	color: #F5F5F5;
    	background: none;
    	background-color:#8C8C8C;
    }

    .datagrid table .col_edit{
    	width: 120px
    }

    .datagrid table .col_delete{
    	width: 120px
    }

    .footerSetting {
      height:100px;
    }
