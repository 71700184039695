.unitpayload-board_1 {
  margin-bottom: 5px!important;
}

.unitpayload-board_2 {
  margin-bottom: 61px!important;
}

.unitpayload-board_3 {
  margin-bottom: 61px!important;
  margin-left: 0px;
  height: 340px!important;
  background-color: #191f27;
  width: 99.5%!important;
}

.unitpayload-BarTitle {
  margin-top: 0px!important;
}

.board-selector-date {
      margin-bottom: 5px!important;
}

.board-row {
  height: 100%!important;
}

.board-button {}

.board-bar-title {}

.board-margin-bottom {
    margin-bottom: 8px!important;
    height: 200%!important;
}

.board-box-time {}

.board-payload {
    padding: 0!important;
}

.board-cycle {}

.board-box-speed {
    margin-top: 2px!important;
}

.board-donut {
    margin: 0!important;
    padding: 0!important;
    height: 25.4em!important;
}

@media only screen and (min-width: 960px) {
    .board-donut-r {
        height: 27.2em!important;
    }
}

.board-monitor-title {
    color: white;
    font-size: large;
    background-color: #191f27;
    height: 10%!important;
    margin-top: 3px!important;
}

.board-monitor {
    padding: 0!important;
}

.box-iz {
    margin-right: 1%;
    margin-left: 0.5%;
    width: 49%!important;
    height:165px;
}

.box-der {
    width: 49%!important;
    height:165px;
}

.tolva_box-der {
    width: 30%!important;
    height:400px;
}

.tolva_box-iz {
    margin-right: 1%;
    margin-left: 0.5%;
    width: 60%!important;
    height:400px;
}

.box-der-row {
}

.box-componente-down {}

.table-datatable {
    padding: 10px;
    margin-top: 20px;
}

.TimeonEach{
  background: #191f27;
  color: #FFFFFF;
  font-size: large;
}

.box-graphPayload{
    margin-top: 35px;
}
