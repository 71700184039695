.Menu img {
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

.Menu-box {
    background-color: rgba(45, 51, 59, 0.6);
}

.menu-table {
    text-align: center;
    color: rgb(153, 153, 153);
}

.menu-tr {}

.menu-space {
    width: 500px!important;
}

.pointer {
    opacity: 0.8!important;
    color:white;
    cursor: pointer!important;
}

.menu-td {
    font-size:  xx-large;
    cursor: not-allowed;
    opacity: 0.6;
    background: black;
     text-align: center;
    height: 200px;
    width: 20%;
     border-style: solid;
    border-width: 10px;
    border-color: transparent;
 }

.selected {
    font-size:  xx-large!important;
    border-style: solid;
    border-width: 0px;
    border-color: transparent;
    opacity: 0.35!important;
    background: grey;
    text-align: center;
    height: 200px;
    width: 20%;
    white-space: nowrap;
    margin:0px!important;
}

.menu-content {
    opacity: 1!important;
    color: #FFFFFF;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: not-allowed;
}

.menu-table-div {
    padding-top: 15%;
}

.imagen-menu{
    opacity: 0.35!important;
    position: inherit!important;
    min-height: auto!important;
    min-width: auto!important;
    height: 100%!important;
    width: 100%!important;

}

.asset {
    background: #572EEE;
}

.cycle {
    background: #A994F6;
}

.menu-payload {
    background: #8497B0;
}

.reporting {
    background: #382C4C;
}

.map{
    background: #2F5597;
}

.menu-boton{
    margin-top: 10px;
    margin-left: 10px;
    float: right;
    color: white;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently*/
    cursor: pointer;
 }

 .menu-map-content{
     position: absolute;
     top:50%;
     left:50%;
     transform: translate(-50%, -50%);
 }

 .imagen-map-conentedor{
     position: relative;
     display: inline-block;
     text-align: center!important;
 }
