.Assetsbar {
    color: white;
    font-size: 1.2em;
    margin-bottom: 0!important;
    background-color: #1d1c1c;
}

.Assetsbar-title {
    width: max-content!important;
    margin-left: 1em!important;
    margin-right: 3em!important;
}

.Assetsbar-overview {
    margin-right: 3%!important;
    width: max-content!important;
    background-color: #1b1b1b;
}

.Assetsbar-sub-title {
    padding-right: 3%!important;
    text-align: center;
    width: max-content!important;
    margin-right: 0.1em!important;
}

.Assetsbar-button {
    padding: 0!important;
    margin: 0!important;
    width: max-content!important;
    margin-left: 10px!important;
}

.Assetsbar-button-a {
    background-color: grey;
    width: 14px;
    height: 25px;
}

.Assetsbar-button-a-i {
    margin-left: -10px!important;
    font-size: 1rem!important;
}
