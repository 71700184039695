.donut {
    background-color: #191f27;
    margin: 0!important;
    padding: 0!important;
    box-sizing: inherit!important;
    height: 38%!important;
}

.canvas {
  margin-top: 10px;
  height: 85%!important;
}

.donut-title {
    color: #7F60F2;
    font-size: large;
    font-weight: bold;
    padding-top: 0px;
    padding-left: 5px;
    margin-bottom: -25px;
}
