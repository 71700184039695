.rw_row_wear {
  margin-bottom: 1px;
}

.rw_cell_table{
  background-color: #1d1c1c;
  height: 30px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 0px;
  padding-top: 10px;
}
