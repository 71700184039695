.sidenav {
    width: 145px!important;
}

.sidenav-menu-opcion {
    padding: 0px!important;
    margin-left: 5px;
    font-size: 0.8em!important;
    text-align: left;
}

.icon-text {
    margin-top: 5px!important;
    margin-right: 5px!important;
}

.sidenav {
    color: white;
    background-color: #323f4f;
    padding-top: 10px;
    min-height: 500px;
    overflow-y: auto;
}

@media only screen and (min-width: 993px) {
    .sidenav {
        top: 70px !important;
    }
}

.waves-effect.waves-teal .waves-ripple {
 /* The alpha value allows the text and background color
 of the button to still show through. */
  background-color: rgba(6,227,35,255)!important;
}
