main {
  position: absolute;
  top: 70px;
  bottom: 0;
  overflow-y: auto;
  overflow-x: auto;
  background-color: #000000;
  width: 100%;
}

.dash-container {
  height: 100%!important;
}

.no-margin {
  height: 100%!important;
  margin-bottom: 0!important;
}

.dashboard{
  height: 100%!important;

}
@media only screen and (min-width: 993px) {
  /* header {
      padding-left: 300px;
    } */
  .sidenav {
    top: 65px !important;
  }
  .logo {
    bottom: 130px;
  }
  .container {
    width: 100%;
  }
  main {
    padding-left: 145px;
  }
  main .container {
    width: 100%;
    max-width: 1500px;
    height: 100%;
    background-color: #333544;
  }
}
