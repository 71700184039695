.apexcharts-yaxis-texts-g text{
    color:white;
    fill:white;
}

.apexcharts-xaxis-texts-g text{
    color:white;
    fill:white;
}

.no-data-chart {
  color:red;
  margin-top: 20px;
}
