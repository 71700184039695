 .fleet {
     padding: 0!important;
 }

 .fleet-title {
     margin-bottom: 5px!important;
 }

 .fleet-iz {
     padding: 0!important;
     padding-right: 2px!important;
 }

 .fleet-iz-row {
     margin: 0!important;
     padding: 0!important;
 }

 .fleet-margin-bottom {
     margin-bottom: 8px!important;
 }

 .fleet-g {
     margin-bottom: 10px!important;
 }

 .fleet-selectordate {}

 .fleet-boxtime {}

 .fleet-graphMultiLine-load {}

 .fleet-graphMultiLine-dump {}

 .fleet-raphBar {
     height: 210px!important;
     background-color: #191f27;
 }

 .fleet-DummyBar {
     height: 85px!important;
     background-color: #191f27;
     color: white;
     font-size: larger;
 }

 .fleet-der {
     padding: 0!important;
     padding-left: 2px!important;
 }

 .fleet-der-row {
     margin: 0!important;
     padding: 0!important;
 }

 .fleet-availability {}

 .fleet-availability-graph {
     height: 345px!important;
 }

 .fleet-cycle-title {
   margin: 0!important;
     background-color: #191f27;
     color: white;
     font-size: larger;
 }

 .fleet-Availability-title {
   margin: 0!important;
     background-color: #191f27;
     color: white;
     font-size: larger;
 }

 .fleet-cycle {
 }
