.bigmap-truck {
    width: 60px;
}

.bigmap-truck-text {
    text-align: center;
    margin-left: 10px;
    background-color: ivory;
    border-radius: 2px;
    white-space: nowrap;
}

.bigmap-name-area {
    max-width: "450px"!important;
    height: "350px"!important;
    overflow-x: "hidden"!important;
    overflow-y: "hidden"!important;
    color: black;
    background-color: black;
    border-radius: 20px;
}

.bigmap-name-area-span {
    max-width: "450px"!important;
    height: "150px"!important;
    overflow-x: "hidden"!important;
    overflow-y: "hidden"!important;
    background-color: yellow;
    padding: 0.7em;
}
