.monitor {
    background-color: #1A2028;
    color: white!important;
    padding: 0!important;
    margin: 0!important;
    height: 100%!important;
}

.monitor-row-col-top {
    height: 50%!important;
}

.monitor-row-col-botton {
    height: 50%!important;
}

.monitor-row {
    padding: 0!important;
    margin: 0!important;
}

.card-nexus {
    background-color: #333544!important;
}

.nexus-general-title {
    color: #767171;
    font-family: Sans-serif;
    text-align: center;
    font-size: 1em!important;
    padding-bottom: 6px!important;
}

.nexus-general-information-row {
    height: 150px;
    background-color: #333544!important;
    font-size: 1em;
    font-family: Sans-serif;
}

.nexus-status-row {
    height: 150px;
    background-color: #333544!important;
    font-family: Sans-serif;
}

.nexus-status-text {
    margin-top: 3px!important;
    font-size: 0.8em!important;
}

.nexus-status-text-key {
    margin-top: 3px!important;
    margin-left: -5px!important;
}

.nexus-status-text2 {
    padding: 0!important;
}

.nexus-general-information {
    margin-left: 1%!important;
    margin-right: 1%!important;
    margin-top: 10px;
    width: 38%!important;
}

.nexus-status {
    margin-right: 1%!important;
    width: 59%!important;
    margin-top: 10px;
}

.nexus-key {
    width: 45%!important;
    white-space: nowrap;
    margin-top: 6px;
    padding-bottom: 6px!important;
    color: #767171;
}

.nexus-value {
    width: 55%!important;
    margin-top: 6px;
    padding-bottom: 6px!important;
    text-align: right;
    white-space: nowrap;
}

.nexus-value-no-wrap{
    white-space: nowrap;
}

.nexus-line-botton {
    border-bottom: 1px solid red;
    border-color: #767171;
}

.nexus-payload {
    margin-left: 1%!important;
    margin-right: 1%!important;
    width: 38%!important;
}

.nexus-payload-row {
    height: 180px;
    background-color: #333544!important;
    font-size: 0.9vw!important;
    font-family: Sans-serif;
}

.nexus-payload-bar {
    margin-top: -10px!important;
    height: 130px!important;
    padding: 0!important;
}

.nexus-time {
    margin-right: 1%!important;
    width: 17%!important;
}

.nexus-time-row {
    padding: 0!important;
    height: 180px;
    background-color: #333544!important;
    font-size: 1em;
    font-family: Sans-serif;
}

.nexus-time-title {
    color: #767171;
    font-family: Sans-serif;
    text-align: center;
    font-size: 1em;
}

@media only screen and (max-width: 1280px) {
    .nexus-time-title-r {
        font-size: 0.8em;
    }
}

.nexus-time-value {
    white-space: nowrap;
    font-family: Sans-serif;
    text-align: center;
    font-size: 1.2em;
}

.nexus-time-sub-title {
    white-space: nowrap;
    color: #767171;
    font-family: Sans-serif;
    text-align: center;
    font-size: smaller;
}

.nexus-gage {
    width: 41%!important;
}

.nexus-gage-row {
    height: 180px;
    background-color: #333544!important;
    font-size: medium;
    font-family: Sans-serif;
}

.nexus-gage-graph-title {
    text-align: center;
    font-size: large;
    color: yellow;
    margin-bottom: 1.5em;
    margin-top: 0.5em;
}

.nexus-gage-title {
    white-space: nowrap;
    margin-top: 0.1em;
    text-align: left;
    font-size: 0.8em;
    color: #767171;
}

.nexus-gage-value {
    white-space: nowrap;
    margin-top: 0.1em;
    text-align: center;
    font-size: 0.8em;
}

.nexus-gage-line-top {
    border-top: 1px solid red;
    border-color: #767171;
}

.nexus-gage-graph {
    padding: 0!important;
    margin: 0!important;
}
